import React, { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Card from "@mui/material/Card";
import queryString from "query-string";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import PayPalBtn from "../components/PayPalBtn";
import arrow from "../images/arrow-white.png";
import Hidden from "@mui/material/Hidden";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "js-cookie";
import {
  checkPro,
  getToken,
  isLoggedIn,
  removePro,
  setPro,
  setToken,
} from "../lib/user.auth";
import { navigate, graphql } from "gatsby";
import { postToApi } from "../lib/api";
import { isBrowser } from "../lib/config";
import creditcard from "../images/credit-card2.jpg";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      process.env.NODE_ENV === "development"
        ? "pk_test_51K160kCBDHuiV2AMymoYxFYD6in0Ab5HZAlxZ7GY8qsOKqzo0i5QwRS18o1UFOzKOy4jRdnr56Qoou5vL2YCnh7K00vwZgz5GX"
        : "pk_live_51K160kCBDHuiV2AMto6P4FLXbYt0Uy7UeW5cWLtx33rXlfB0EKm2ZfM4XOPkCLHVngaL8hOW5k3fSlRPh7BBvoi400HCw9QoY1"
    );
  }
  return stripePromise;
};

const Pricing = () => {
  const { t } = useTranslation();

  const p_id_yearly =
    process.env.NODE_ENV === "development"
      ? "price_1K16NCCBDHuiV2AMWjZmSKtJ"
      : "price_1K16oZCBDHuiV2AMJcYzDqTK";

  const p_id_monthly =
    process.env.NODE_ENV === "development"
      ? "price_1K16PPCBDHuiV2AMFJbvvHQ3"
      : "price_1K16oPCBDHuiV2AMI3pFf8YP";

  const runStripe = async () => {
    //console.log("Run the payment ...");
    const url = window.location.origin + window.location.pathname;
    try {
      const stripe = await getStripe();
      const d = new Date();
      let time = d.getTime();
      Cookies.set("checkuser", time);
      const session = await stripe.redirectToCheckout({
        mode: "subscription",
        locale: "en",
        lineItems: [
          {
            price: prodId,
            quantity: 1,
          },
        ],
        successUrl: `${url}?dKpqoamoidquwiFsadHJwpdasunzduaifitrewGGfsSazsdfzdb=${time}&success=true&session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${url}?canceled=true`,
      });
      navigate(session.url);
    } catch (err) {
      console.log(err);
    }
  };

  const [success, setSuccess] = React.useState(false);

  const planYearly =
    process.env.NODE_ENV === "development"
      ? "P-01P77158M2088632WMDS23VI"
      : "P-8DP166324T117890NMDTAVLA";
  const planMonthly =
    process.env.NODE_ENV === "development"
      ? "P-01P77158M2088632WMDS23VI"
      : "P-2GP27586DS537343PMDTAWLY";

  const [planId, setPlanId] = useState(planYearly);
  const [prodId, setProdId] = useState(p_id_yearly);
  const [alignment, setAlignment] = useState("yearly");
  const [showPrice, setShowPrice] = useState("0,86");
  const [showPriceBottom, setShowPriceBottom] = useState(
    <>
      Pay <s>39,96€</s> 10,24€ yearly
    </>
  );

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: planId,
    });
  };

  const paypalOnError = (err) => {
    removePro();
    alert("There was an unexpected error. Please try again later.");
    console.log("Error");
  };

  const paypalOnApprove = async (data, detail) => {
    // call the backend api to store transaction details
    //console.log("Payapl approved");
    const result = await postToApi("/1.0/add-plan", {
      subscriptionID: data.subscriptionID,
    });
    if (result.code === 200) {
      setPro();
      navigate("/success");
    } else {
      alert("There was a problem. Please contact our support.");
    }
    //console.log(data.subscriptionID);
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment === null) newAlignment = alignment;
    setAlignment(newAlignment);
    if (newAlignment === "yearly") {
      setPlanId(planYearly);
      setProdId(p_id_yearly);
      setShowPriceBottom(
        <>
          Pay <s>39,96€</s> 10,24€ yearly
        </>
      );
      setShowPrice("0,86");
    } else {
      setPlanId(planMonthly);
      setProdId(p_id_monthly);
      setShowPriceBottom(<>Pay monthly</>);
      setShowPrice("3,33€");
    }
  };

  useEffect(() => {
    if (isBrowser()) {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
      setSuccess(false);
      if (query.get("success") && query.get("success")) {
        if (
          query.get("dKpqoamoidquwiFsadHJwpdasunzduaifitrewGGfsSazsdfzdb") ===
            Cookies.get("checkuser") &&
          Cookies.get("checkuser") != null
        ) {
          setSuccess(true);
          paypalOnApprove({ subscriptionID: "stripe" });
        } else {
          Cookies.remove("checkuser");
          paypalOnError();
        }
        return;
      }
      if (query.get("canceled")) {
        Cookies.remove("checkuser");

        paypalOnError();
        return;
      }
      let url = window.location.search;
      let params = queryString.parse(url, { decode: false });
      if (params.__) {
        setToken(params.__);
      }
    }
    if (checkPro()) navigate("/success");
    //console.log(process.env.NODE_ENV);
  }, [success]);

  return (
    <Layout>
      <Seo title={t("Pricing plans")} />
      <h1>{t("Pricing plans")}</h1>
      {!getToken() ? (
        <>
          <h2>{t("It's free for usage")}</h2>
          <div dangerouslySetInnerHTML={{ __html: t("text") }} />
        </>
      ) : (
        <h2>{t("Only one plan for ALL features! NO hidden cost.")}</h2>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="flex-end"
        style={{ marginTop: "40px" }}
      >
        <Grid item xs={12} md={12} style={{ marginBottom: "40px" }}>
          <Grid container justifyContent="center" alignItems="flex-end">
            <Grid item>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
              >
                <ToggleButton value="monthly">
                  {t("Monthly billing")}
                </ToggleButton>
                <ToggleButton value="yearly">
                  {t("Yearly billing")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Hidden smDown>
              <Grid item style={{ marginTop: -50 }}>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -80,
                    marginLeft: 20,
                  }}
                >
                  <img
                    alt="..."
                    src={arrow}
                    width="50"
                    height="50"
                    className="pricing_arrow"
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    marginTop: -80,
                    marginLeft: 80,
                  }}
                >
                  <small>{t("save2")} 74%</small>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="flex-end">
          {!isLoggedIn() && (
            <>
              <Grid item key="free" xs={12} md={3}>
                <Card>
                  <CardHeader
                    title="Starter"
                    titleTypographyProps={{ align: "left" }}
                    style={{ background: "#e9e9e9" }}
                  />
                  <CardContent>
                    <ul>
                      <li>
                        <small>{t("startup")}</small>
                      </li>
                    </ul>
                    <Typography component="h2" variant="h5" color="textPrimary">
                      Free forever
                    </Typography>
                  </CardContent>
                  <Grid style={{ margin: 20 }}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        navigate("/accounts");
                      }}
                    >
                      {t("Sign up")}
                    </Button>
                  </Grid>
                </Card>
                <br />
                <br />
              </Grid>
              <Grid item key="spacer" xs={12} md={1}></Grid>
            </>
          )}
          <Grid item key="pro_y" xs={12} md={5}>
            <Card>
              <CardHeader
                title="Pro"
                subheader="Unlock all features of my-follower.com"
                titleTypographyProps={{ align: "left" }}
                subheaderTypographyProps={{ align: "left" }}
                action={<StarIcon />}
                style={{ background: "#e9e9e9" }}
              />
              <CardContent>
                <ul>
                  <li>
                    <span>✓</span> {t("No ads")}
                  </li>
                  <li>
                    <span>✓</span> {t("All social media platforms")}
                  </li>
                  <li>
                    <span>✓</span> {t("Multiple social media accounts")}
                  </li>
                  <li>
                    <span>✓</span> {t("Individual times for the e-mail")}
                  </li>
                </ul>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                  }}
                >
                  <Typography component="h2" variant="h3" color="textPrimary">
                    {showPrice}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /{t("month")}
                  </Typography>
                </div>
                <ul>
                  <Typography align="center" key="free_line">
                    <small>{showPriceBottom}</small>
                  </Typography>
                </ul>
              </CardContent>
              <Grid style={{ margin: 20 }}>
                {!isLoggedIn() ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate("/accounts");
                    }}
                  >
                    Go pro!
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      style={{ width: "100%", backgroundColor: "#f2f2f2" }}
                      onClick={() => runStripe()}
                    >
                      <img
                        src={creditcard}
                        alt="Credit Card"
                        title="Credit or Debit Card via Stripe"
                        border="0"
                        style={{ width: "100%", margin: 0 }}
                      />
                    </Button>
                    <br />
                    <center>
                      <small>via Stripe</small>
                    </center>
                    <br />
                    <PayPalBtn
                      currency="EUR"
                      createSubscription={paypalSubscribe}
                      onApprove={paypalOnApprove}
                      catchError={paypalOnError}
                      onError={paypalOnError}
                      onCancel={paypalOnError}
                    />
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
